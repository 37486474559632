import { Autocomplete, Box, Container, Grid, TextField } from "@mui/material";

import { DateRangePicker } from "rsuite";
import "rsuite/dist/rsuite.min.css";
type Props = {
  groups: any;
  agents: any;
  agentSelected: any;
  groupSelected: any;

  filterPos: string;
  onFilterPosChange: (pos: string) => void;

  onGroupChange: (group: any) => void;
  onAgentChange: (agent: any) => void;

  date: Date[];

  onDateChange: (date: Date) => void;
};

export const ControlFilter = (props: Props) => {
  const {
    groups,
    agents,
    groupSelected,
    agentSelected,
    onGroupChange,
    onAgentChange,
    date,
    onDateChange,
  } = props;

  return (
    <div style={{ position: "absolute", width: "100%", top: 0 }}>
      <Container>
        <Box
          sx={{
            bgcolor: "#FFF",
            borderBottomRightRadius: 20,
            borderBottomLeftRadius: 20,
            boxShadow: 3,
          }}
        >
          <Grid container wrap={"wrap"} spacing={2} padding={2}>
            <Grid item sm={4}>
              <Autocomplete
                disablePortal
                id="group"
                value={groupSelected}
                options={groups}
                onChange={(event, newValue) => {
                  onGroupChange(newValue);
                }}
                getOptionLabel={(opt) => opt.name.toString()}
                renderOption={(props, opt, state) => (
                  <li {...props} key={opt.id}>
                    {opt.name}
                  </li>
                )}
                renderInput={(params) => (
                  <TextField {...params} label="Group" />
                )}
              />
            </Grid>
            <Grid item sm={4}>
              <Autocomplete
                disablePortal
                value={agentSelected}
                onChange={(event, newValue) => {
                  onAgentChange(newValue);
                }}
                getOptionLabel={(option) => option.name.toString()}
                id="agent"
                options={agents}
                renderInput={(params) => (
                  <TextField {...params} label="Agent" />
                )}
              />
            </Grid>
            <Grid item sm={4}>
              <div>
                <TextField
                  fullWidth
                  id="outlined-post-input"
                  label="User"
                  value={props.filterPos}
                  type="search"
                  onChange={(event) => {
                    props.onFilterPosChange(event.target.value);
                  }}
                />
              </div>
            </Grid>
            {/* <Grid item sm={3}>
              <div>
                <TextField
                  fullWidth
                  id="outlined-ticket-input"
                  label="Ticket"
                  type="search"
                  onChange={(event) => {
                    // setFilterTicket(event.target.value);
                  }}
                />
              </div>
            </Grid> */}

            <Grid item sm={12}>
              <DateRangePicker
                value={date as any}
                onChange={(value) => {
                  onDateChange(value as any);
                }}
                size="lg"
                block
              />
            </Grid>
          </Grid>
        </Box>
      </Container>
    </div>
  );
};
