import { Box, FormControlLabel, FormGroup, Switch } from "@mui/material";
import { mapStyles } from "../../../../utils/map";

type Props = {
  onMapStyleChange: (newStyle: any) => void;
};

export const ControlStyle = (props: Props) => {
  const { onMapStyleChange } = props;

  return (
    <Box
      sx={{
        bgcolor: "#FFF",
        borderRadius: 10,
        padding: 2,
        boxShadow: 3,
        position: "absolute",
        left: 30,
        top: 10,
      }}
    >
      <FormGroup>
        <FormControlLabel
          control={
            <Switch
              onChange={(event) => {
                if (event.target.checked) {
                  onMapStyleChange(mapStyles[2]);
                } else {
                  onMapStyleChange(mapStyles[0]);
                }
              }}
            />
          }
          label="Active Real"
        />
      </FormGroup>
    </Box>
  );
};
